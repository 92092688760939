<template>
  <div>
    <Carousel :settings="settings" :breakpoints="breakpoints">
      <Slide class="slider-spaces__cont" v-for="(space, index) in spacesToShow" :key="index">
        <div class="slider-spaces__card" :class="space.slug">
          <div class="slider-spaces__img-cont" v-if="space['space_thumbnail'] || space['space_thumbnail_r'] ">
            <img v-if="space['space_thumbnail']" loading="lazy" :src="`${space['space_thumbnail']['public_path']}?size=entry`" :alt="space['space_thumbnail']['alt']" class="slider-spaces__img img-fluid">
            <img v-else-if="space['space_thumbnail_r']" loading="lazy" :src="`${space['space_thumbnail_r']['public_path']}?size=entry`" :alt="space['space_thumbnail_r']['alt']" class="slider-spaces__img img-fluid">
          </div>
          <div class="slider-spaces__info">
            <div class="d-flex flex-column">
              <h4 v-if="space.space_is_coming_soon" class="slider-spaces__h4 mb-1">
                {{ space.title }}
              </h4>
              <a v-else class="slider-spaces__h4"
                :href="`/${locale}/${spaceUrl(space)}`"
              >
                {{ space.title }}
              </a>
              <p v-if="space.space_is_coming_soon" class="slider-spaces-coming-soon__p mb-2">
                Coming soon
              </p>
              <p class="slider-spaces__p"
                :class="{'slider-spaces-hidden__p': !space.space_is_coming_soon }"
                style="white-space: pre-line"
                v-html="space.blurb"
              >
              </p>
            </div>

            <div class="d-flex justify-content-between align-items-end" v-if="!space.space_is_coming_soon">
              <div class="d-flex flex-column">
                <span class="slider-spaces__span" v-if="!status.prices.loading && prices?.[space.slug]" >
                    {{ locale == 'en' ? 'From' : 'Desde'}}
                </span>
                <span v-if="status.prices.loading" class="slider-spaces__span">
                    $<span class="spinner"></span>
                </span>
                <span v-else-if="prices?.[space.slug]" class="slider-spaces__span">
                    {{ `$${prices[space.slug] }` }}
                </span>
              </div>
              <a class="btn button-space invert d-flex align-items-center flex-nowrap"
                :href="`/${locale}/${spaceUrl(space)}`"
              >
                <span class="me-2" v-if="[ 'cook-space', 'cafeteria-planta-baja'].includes(space.slug)">{{ locale == 'en' ? 'Menu and more' : 'Menú y más'}}</span>
                <span class="me-2" :style='`color: var(--${(space.slug)}-bg);font-weight:600`' v-else>{{ locale == 'en' ? 'Learn more' : 'Conoce más'}}</span>

                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" view-box="0 0 15 10">
                  <path d="M9.899.118 9.24.775a.402.402 0 0 0 .006.574l2.704 2.597H.402A.402.402 0 0 0 0 4.348v.938c0 .222.18.402.402.402h11.55L9.246 8.284a.402.402 0 0 0-.005.575l.657.657a.402.402 0 0 0 .568 0L14.882 5.1a.402.402 0 0 0 0-.568L10.467.118a.402.402 0 0 0-.568 0z">
                  </path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </Slide>

      <template #addons>
        <div class="d-flex d-md-none w-100 justify-content-center">
          <Pagination />
        </div>
        <Navigation />
      </template>
    </Carousel>

  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'


export default defineComponent({
  name: 'Breakpoints',
  inject: ["locale", "$axios"],
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination
  },
  props: {
    spaces: Array,
    productSlugToHide: String
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1.1,
      snapAlign: 'center',
    },
    prices: {},
    status: {
        prices: {loading: false}
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      400: {
        itemsToShow: 1.1,
        snapAlign: 'start',
      },
      480: {
        itemsToShow: 1.2,
        snapAlign: 'start',
      },
      575: {
        itemsToShow: 1.6,
        snapAlign: 'start',
      },
      768: {
        itemsToShow: 2.1,
        snapAlign: 'start',
      },
      1000: {
        itemsToShow: 2.8,
        snapAlign: 'start',
      },
      1199: {
        itemsToShow: 3.4,
        snapAlign: 'start',
      },
    },
  }),
  created() {
    this.getPrices();
  },
  computed: {
    spacesToShow() {
      if (this.productSlugToHide){
        return (this.spaces ? this.spaces.filter( x => x.slug != this.productSlugToHide) : []);
      }
      return (this.spaces ? this.spaces : []);
    },
  },
  methods: {
    spaceUrl(space){
      if (space.hasOwnProperty('story_id') && space.story_id === 79){
        return `cook-space`
      }
      return `${this.locale == 'en' ? 'spaces' : 'espacios'}/${space.slug}`
    },
    getPrices() {
      this.status.prices.loading = true;
      this.$axios.get('/api/v1/product-prices/' + this.locale)
      .then(r => {
          if (r['data'].length == 0) {
              return
          }
          this.prices = r['data'].reduce((acc, x) => {
              return {...acc, [x.slug]: this.getFormatPrice(x.relationships.product.skus[0])};
          }, {});
      }).finally(() => {
        this.status.prices.loading = false;
      })
    },
      getFormatPrice(sku){
        let price = sku.price.toLocaleString("en", {useGrouping: true})
        let currency = sku.currency
        return `${price} ${currency}`
      }
  }
})
</script>
<style lang="scss">
@import 'https://cdn.jsdelivr.net/npm/vue3-carousel@0.2.9/dist/carousel.min.css';

.slider-spaces__card {
  width: 94%;
  margin: 0 auto;
  height: 100%;
  border-radius: 15px;
}

.slider-spaces__info {
  background-color: white;
  padding: 15px;
  text-align: left;
  border-radius: 0px 0px 15px 15px;
  height: 54%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .slider-spaces__span {
    color: var(--muted);
    font-size: 14px;

    +.slider-spaces__span {
      color: var(--deep-muted);
      font-weight: 700;
      font-size: 18px;
    }
  }
}

.slider-spaces__img-cont{
  height: 46%;
  width: 100%;
  object-fit: cover;
}

.slider-spaces__img {
  border-radius: 15px 15px 0 0;
  width: 100%;
  height: 100%;
}

.slider-spaces__h4 {
  color: var(--space-bg-color) !important;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: capitalize;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slider-spaces__p {
  font-size: 15px;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.slider-spaces-hidden__p {
  overflow: hidden;
}

.slider-spaces-coming-soon__p {
  font-size: 0.9em;
  font-style: italic;
  color: var(--muted);
}

.space-btn {
  border: 1px solid var(--open-space);
  padding: 5px 15px;
  border-radius: 15px;
  color: var(--open-space);
}

.accordion-body,
.accordion-button {
  font-family: inherit;
}

.accordion-button:hover {
  color: inherit;
}
.slider-spaces_arrow{
  color:red;
}

.carousel__pagination{
  padding-left: 0;
  .carousel__pagination-button {
    display: block;
    border: 0;
    margin: 0;
    cursor: pointer;
    padding: 6px;
    background: 0 0;
    &::after{
      width: 35px;
      height: 8px;
      border-radius: 5px;
      background-color: #D8D8D8;
    }
  }
  .carousel__pagination-button--active{
    &::after{
      background-color: #4196CD;
    }
  }
}
@media (max-width:575px){
  .slider-spaces__h4 {
    font-size: 16px;
  }
  .slider-spaces__p{
    font-size: 13px;

  }
}
</style>