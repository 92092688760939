import App from '@components/sliders/SliderSpaces.vue'
import { generateApplication } from '@helpers/generate_component.js';
import { generateAxiosInstance } from '@helpers/axios_provider.js';

const axios = generateAxiosInstance();
const injections = [{
    name: 'locale',
    module: locale
}, {
    name: '$axios',
    module: generateAxiosInstance()
}]

const props = {
    spaces: spaces_data,
    productSlugToHide: typeof product !== 'undefined' ? product.slug : null
}
generateApplication(App, "#slider-spaces", { injections, props })